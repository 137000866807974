import React from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";

import ChevronD from "@svg/chevron-d.svg";

import { useCategoryQuery } from "@querys/useCategoryQuery";

const menuVars = {
  open: {
    opacity: 1,
    y: 10,
  },
  closed: {
    opacity: 0,
    y: 40,
  },
};

const MobileCatNav = ({ items, active }) => {
  const [isOpen, setOpen] = React.useState(false);

  const toggleMenu = () => setOpen((prev) => !prev);

  return (
    <div className="relative md:hidden">
      <button
        className="btn-standard text-red w-full justify-between items-center"
        onClick={toggleMenu}
      >
        {active || "All"}{" "}
        <ChevronD className="w-[10px] fill-current text-red " />
      </button>
      <motion.div
        className={`bg-white absolute w-full p-6 transform translate-y-2 grid left-0 rounded-lg shadow-md gap-y-4 ${!isOpen && "pointer-events-none"}`}
        animate={isOpen ? "open" : "closed"}
        variants={menuVars}
      >
        <Link to="/news" className="text-red" activeClassName="underline">
          All
        </Link>
        {items.map((item) => (
          <Link
            to={`/news/${item?.slug?.current}`}
            className="text-red"
            activeClassName="underline"
          >
            {item?.title}
          </Link>
        ))}
      </motion.div>
    </div>
  );
};

const CategoryMenu = ({ active }) => {
  const categories = useCategoryQuery();
  return (
    <div className="relative z-75 px-gutter">
      <MobileCatNav items={categories} active={active} />
      <nav className="hidden md:flex justify-center gap-x-6  pb-space-s text-red">
        <Link to="/news" className="btn-cat" activeClassName="active">
          All
        </Link>
        {categories.map((item) => (
          <Link
            to={`/news/${item?.slug?.current}`}
            className="btn-cat"
            activeClassName="active"
          >
            {item?.title}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default CategoryMenu;
