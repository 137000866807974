import React from 'react';
import { Link } from 'gatsby';

import { SanityImage } from '@components/sanity/sanityImage';

const CardWrapper = ({ wrapper, children }) => {
  return wrapper(children);
};

const BlogCard = ({ slug, cardImage, title, excerpt, category, url, isNewsletter, postDate }) => {
  return (
    <CardWrapper
      wrapper={(children) =>
        !isNewsletter ? (
          <Link to={`/news/${slug?.current}`} className="grid gap-y-4">
            {children}
          </Link>
        ) : (
          <a href={url} target="_blank" className="grid gap-y-2">
            {children}
          </a>
        )
      }
    >
      <React.Fragment>
        <div className="relative rounded-lg overflow-hidden">
          {category?.title && (
            <span className="rounded-full text-red bg-white text-xs font-extra-bold absolute left-4 top-4 z-10 py-2 px-6">
              {category?.title}
            </span>
          )}
          <div className="aspect-w-5 aspect-h-3 relative w-full">
            <SanityImage
              image={cardImage}
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          </div>
        </div>
        <h4 className="mt-one text-xs font-bold uppercase">{postDate}</h4>
        <h3 className="text-2xl text-red">{title}</h3>
        <p className="">{excerpt}</p>
        <span className="text-red text-sm font-bold lg:text-base">Read Article</span>
      </React.Fragment>
    </CardWrapper>
  );
};

export default BlogCard;
